import { BLOGS_BASE_URL } from "../../utils/utils";

export const getBlogCategory = (params) => {
    const { pageNo } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${BLOGS_BASE_URL}blogs/category?page=${pageNo}`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};
export const getBlogSubCategory = (params) => {
    const { pageNo, catId } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    const url = catId?`${BLOGS_BASE_URL}blogs/sub-category/${catId}?page=${pageNo}`:`${BLOGS_BASE_URL}blogs/sub-category?page=${pageNo}`;
    return fetch(url, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};
export const getBlogListing = (params) => {
    const { pageNo, subcatId } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${BLOGS_BASE_URL}blogs/by-subcat/${subcatId}?page=${pageNo}`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const getMyBlogsList = (params) => {
    const { pageNo, token, filterType } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    // let formData = new FormData();
    // formData.append('article_id', articleId);
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${BLOGS_BASE_URL}blogs/my-blogs?status=${filterType}&page=${pageNo}`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const getBlogDetails = (params) => {
    const { blogId, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${BLOGS_BASE_URL}blogs/detail/${blogId}`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const likeUnlikeDislike = (params) => {
    const { articleId, voteType, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    // formData.append('user_id', user_id);
    formData.append('like', voteType)
    formData.append('article_id', articleId);

    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData
    };
    return fetch(`${BLOGS_BASE_URL}blogs/like-dislike`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const deleteBlog = (params) => {
    const { articleId, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append('article_id', articleId);

    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData
    };
    return fetch(`${BLOGS_BASE_URL}blogs/delete`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const postBlog = (params) => {
    const { title, cat_id, token, scat_id, image, description, type, id } = params;
    // alert(JSON.stringify(params))
    // return;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append('title', title)
    formData.append('description', description);
    formData.append('cat_id', cat_id);
    formData.append('scat_id', scat_id);
    if(id)
    formData.append('id', id);
    if(image)
    formData.append('image', image);   

    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData
    };
    let fUrl = type=="post"?`${BLOGS_BASE_URL}blogs/post`:`${BLOGS_BASE_URL}blogs/post`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};


export const blogsCommentAndReply = (params) => { 
    const { blogId, comment, commentId, token } = params;
    
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`)
    let formData = new FormData();
    formData.append('article_id', blogId);
    formData.append('comment', comment);
    formData.append('commentId', commentId || 0);
  
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData
    };  
    return fetch(`${BLOGS_BASE_URL}blogs/comments`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };